import React from "react";
import { Box, Text } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import VideoBlock from "../VideoBlock";
import lineImg from "./images/line.svg";

import globalAdjustmentsMp4 from "./images/global_adjustments.mp4";
import globalAdjustmentsWebm from "./images/global_adjustments.webm";

import TryItButton from "../TryItButton";

const ImageAnimation = styled(Box)`
	position: relative;
	overflow: hidden;

	& > div {
		width: 100%;
	}

	& > div:last-child {
		animation-name: blink;
		animation-duration: 6s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-out;
		animation-fill-mode: both;

		position: absolute !important;
		z-index: 1;
		top: 0;
		left: 0;
	}

	@keyframes blink {
		0%,
		58.3%,
		100% {
			opacity: 0;
		}
		8.3%,
		50% {
			opacity: 1;
		}
	}
`;

const ImageAnimationTwo = styled(ImageAnimation)`
	& > div:last-child {
		animation-delay: 2.5s;
		animation-direction: reverse;
	}
`;

const OneVision = () => {
	const { globalAdjustments, animaBtn, animaBtn2, animaCard, animaCard2 } =
		useStaticQuery(graphql`
			query {
				site {
					siteMetadata {
						appUrl
					}
				}
				globalAdjustments: file(
					relativePath: { eq: "global_adjustments.jpg" }
				) {
					...videoImage
				}
				animaBtn: file(relativePath: { eq: "anima-button.jpg" }) {
					...videoImage
				}
				animaBtn2: file(relativePath: { eq: "anima-button-2.jpg" }) {
					...videoImage
				}
				animaCard: file(relativePath: { eq: "anima-card.jpg" }) {
					...videoImage
				}
				animaCard2: file(relativePath: { eq: "anima-card-2.jpg" }) {
					...videoImage
				}
			}
		`);

	return (
		<Box>
			<Box
				variant="gridcontainer"
				pt={["102px", "102px", "180px"]}
				pb={["60px", "120px"]}
			>
				<Box
					sx={{
						gridColumn: ["span 6 !important"],
					}}
				>
					<Text variant="text.hero1" color="text.primaryalt">
						One vision.
					</Text>
					<Text
						variant="text.hero2"
						color="text.primaryalt"
						mb="72px"
					>
						One design system.
					</Text>
					<Box
						sx={{
							display: ["none", "block"],
						}}
					>
						<TryItButton />
					</Box>
				</Box>
				<Box
					sx={{
						gridColumn: ["span 6 !important"],
					}}
				>
					<Text variant="text.leadsm" color="text.primary">
						Build, sustain and evolve your unique design system at
						high speed and no drag.
					</Text>
					<Text variant="text.leadsm" color="text.primary" mt="30px">
						Introduce global and laser-precise changes across
						multiple web pages and projects, create universal rules,
						variables and custom presets – all in one cozy space.
						The Graphite Space.
					</Text>
				</Box>
				<Box
					sx={{
						mt: "54px",
						display: ["block", "none"],
					}}
				>
					<TryItButton />
				</Box>
			</Box>
			<VideoBlock
				image={globalAdjustments.childImageSharp.fluid.base64}
				mp4={globalAdjustmentsMp4}
				webm={globalAdjustmentsWebm}
			>
				<Text variant="text.title1" color="text.primaryalt">
					Global and pinpoint adjustments
				</Text>
				<Text variant="text.bodylg" color="text.primary" mt="24px">
					When a new color suddenly becomes the new black, you’ll be
					ready to act on the new trend ahead of the competition.
				</Text>
				<Text variant="text.bodylg" color="text.primary" mt="24px">
					Global adjustments, like changing the color scheme of your
					entire web-site, can be done in just one click. How sick is
					that?
				</Text>
				<Box
					sx={{
						borderRadius: "md.all",
						padding: "36px 30px",
						borderWidth: "1px",
						borderColor: "bg.primaryaltminus",
						borderStyle: "solid",
					}}
					mt="36px"
				>
					<img src={lineImg} alt="" />
					<Text
						variant="text.headlinemd"
						color="text.primary"
						mt="24px"
					>
						Pinpoint adjustments included
					</Text>
					<Text variant="text.bodymd" color="text.primary" mt="12px">
						Looking to deliver a more surgical strike on imperfect
						style? Changing specific elements or containers across
						every page of your web site or project is a matter of
						seconds now.
					</Text>
				</Box>
			</VideoBlock>
			<Box variant="gridcontainer">
				<Box
					sx={{
						gridColumn: [
							"span 6 !important",
							"2 / span 10 !important",
							"span 5 !important",
						],
						mb: ["90px", "70px"],
					}}
				>
					<ImageAnimation sx={{ borderRadius: "md.all" }}>
						<Img fluid={animaBtn.childImageSharp.fluid} />
						<Img fluid={animaBtn2.childImageSharp.fluid} />
					</ImageAnimation>
					<Text
						variant="text.title4"
						color="text.primaryalt"
						mt="48px"
					>
						Universal rules
					</Text>
					<Text
						variant="text.bodylg"
						color="text.primary"
						mt="12px"
						mb="24px"
					>
						{`If you've got one design principle to carry over to each
						and every web page you create, this can be done with no
						sweat. Set up custom variables and apply them to as many
						web-pages and projects you like.`}
					</Text>
				</Box>
				<Box
					sx={{
						gridColumn: [
							"span 6 !important",
							"2 / span 10 !important",
							"8 / span 5 !important",
						],
						mb: "70px",
					}}
				>
					<ImageAnimationTwo sx={{ borderRadius: "md.all" }}>
						<Img fluid={animaCard.childImageSharp.fluid} />
						<Img fluid={animaCard2.childImageSharp.fluid} />
					</ImageAnimationTwo>
					<Text
						variant="text.title4"
						color="text.primaryalt"
						mt="48px"
					>
						Custom presets
					</Text>
					<Text
						variant="text.bodylg"
						color="text.primary"
						mt="12px"
						mb="24px"
					>
						Found a neat design trick, solution or idea? Why not
						pack it in one preset and implement it across all your
						web pages and projects. Graphite Space allows you to
						tweak and even swap entire sections of your
						design-system in one swift move by utilizing the preset
						mechanics.
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default React.memo(OneVision);
