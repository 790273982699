import React from "react";
import { Box, Text } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import VideoBlock from "../VideoBlock";
import TryItButton from "../TryItButton";

import line1Img from "./images/line1.svg";
import line2Img from "./images/line2.svg";
import ellipse from "./images/ellipse.svg";

const gridColumnLeftSx = {
	gridColumn: ["span 6 !important", "span 6 !important", "1 / 6 !important"],
};

const gridColumnRightSx = {
	gridColumn: ["span 6 !important", "span 6 !important", "7 / 12 !important"],
};

const LayerSystem = () => {
	const { layerSystem } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					appUrl
				}
			}
			layerSystem: file(relativePath: { eq: "layer_system.jpg" }) {
				...videoImage
			}
		}
	`);

	return (
		<Box sx={{ position: "relative" }}>
			<Box sx={{ position: "absolute", top: "-140px", left: 0 }}>
				<img src={ellipse} alt="" />
			</Box>
			<Box sx={{ position: "relative", zIndex: "1" }}>
				<Box variant="gridcontainer" pt={["102px", "102px", "180px"]}>
					<Box
						sx={{
							gridColumn: ["span 6 !important"],
						}}
					>
						<Text
							variant="text.hero1"
							color="text.primaryalt"
							mb="72px"
						>
							Layer system
						</Text>
						<Box
							sx={{
								display: ["none", "flex"],
							}}
						>
							<TryItButton />
						</Box>
					</Box>
					<Box
						sx={{
							gridColumn: ["span 6 !important"],
						}}
					>
						<Text
							variant="text.leadsm"
							color="text.primary"
							pb="54px"
						>
							{`East or West, home is best. That's why Graphite Space
						brings the beloved “layers” panel into the webdesign
						game, making the environment extra cozy and familiar,
						and your designer workflow – more fluid and comfortable.`}
						</Text>
						<Box
							sx={{
								display: ["flex", "none"],
							}}
						>
							<TryItButton />
						</Box>
					</Box>
				</Box>
				<Box mt={["90px", "90px", "120px"]}>
					<VideoBlock
						reverse
						image={layerSystem.childImageSharp.fluid}
					>
						<Text variant="text.title1" color="text.primaryalt">
							Flow like water
						</Text>
						<Text
							variant="text.bodylg"
							color="text.primary"
							mt="24px"
						>
							{`There's no more need to 'adjust' your preferred
							design techniques and methods to the (sometimes
							harsh) realities of web development!`}
						</Text>
						<Text
							variant="text.bodylg"
							color="text.primary"
							mt="24px"
						>
							The familiar “Layer Design” can be safely used to
							create a Web Page just like any other piece of
							digital art out there.
						</Text>
					</VideoBlock>
					<Box variant="gridcontainer">
						<Box sx={gridColumnLeftSx} pb="60px">
							<img src={line1Img} alt="" />
							<Text
								variant="text.title4"
								color="text.primaryalt"
								mb="12px"
								mt="30px"
							>
								Smooth Layer-to-Grid transition
							</Text>
							<Text variant="text.bodymd" color="text.primary">
								Not entirely happy with what Bootstrap has to
								offer or need a little extra for a very special
								occasion? Why not create your own grid in a
								matter of minutes: from the desired number of
								columns and gutter values to container width and
								margin and padding properties
							</Text>
						</Box>
						<Box
							sx={gridColumnRightSx}
							pb={["120px", "133px", "200px"]}
						>
							<img src={line2Img} alt="" />
							<Text
								variant="text.title4"
								color="text.primaryalt"
								mb="12px"
								mt="30px"
							>
								Consistent all the way through
							</Text>
							<Text variant="text.bodymd" color="text.primary">
								{`Each and every element will automatically adapt
								to any change you've made to ensure consistency.
								So you needn't worry about how one minor tweak
								will tumble the entire design – we got this part
								covered for you.`}
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default React.memo(LayerSystem);
