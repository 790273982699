import React from "react";
import { Box, Text } from "@graphite/uneon";
import ellipse from "./images/ellipse.svg";
import card1Img from "./images/card1.svg";

const gridColumnLeftSx = {
	gridColumn: ["span 6 !important", "span 6 !important", "1 / 6 !important"],
};

const gridColumnRightSx = {
	gridColumn: ["span 6 !important", "span 6 !important", "7 / 12 !important"],
};

const VisualComponent = () => (
	<Box sx={{ position: "relative" }}>
		<Box sx={{ position: "absolute", top: "-140px", left: 0 }}>
			<img src={ellipse} alt="" />
		</Box>
		<Box sx={{ position: "relative", zIndex: "1" }}>
			<Box variant="gridcontainer" pt={["102px", "102px", "180px"]}>
				<Box
					sx={{
						display: "grid",
						gridColumn: ["span 6 !important"],
					}}
				>
					<Box
						sx={{
							display: "flex",
							order: 1,
						}}
					>
						<Text
							mb="32px"
							sx={{
								height: "21px",
								padding: "3px 9px",
								bg: "bg.accent",
								borderRadius: "99px",
							}}
							variant="text.sectionsm"
							color="text.oncolor"
						>
							Coming soon
						</Text>
					</Box>
					<Text
						sx={{
							order: [3, 2],
							fontSize: ["42.6px", "42.6px", "68px"],
							lineHeight: ["120%", "120%", "72px"],
						}}
						variant="text.hero2"
						color="text.primaryalt"
						mb={["30px", "48px"]}
					>
						Reusable visual components
					</Text>
					<Box
						sx={{
							order: [2, 3],
						}}
						mb={["36px", "96px"]}
					>
						<img src={card1Img} alt="" />
					</Box>
				</Box>
				<Box
					sx={{
						gridColumn: ["span 6 !important"],
					}}
				>
					<Text variant="text.leadsm" color="text.primary" pb="54px">
						Developers are proud of never repeating themselves, but
						as designers – we know better! <br />
						<br /> Why let bright ideas and robust design decisions
						go to waste, when you can reuse them through multiple
						web pages and projects – not just to save time and
						effort, but to ensure a consistent style and design tone
						all the way through.
					</Text>
				</Box>
			</Box>
			<Box variant="gridcontainer">
				<Box sx={gridColumnLeftSx} pb="60px">
					<Text
						variant="text.headlinelg"
						color="text.primaryalt"
						mb="12px"
						mt="30px"
					>
						More style, less effort
					</Text>
					<Text variant="text.bodymd" color="text.primary">
						Create custom visual components and carry the momentum
						of your design drive into the next web page, web site or
						project. This feature allows you to reimplement specific
						design ideas across multiple creations in a matter of
						seconds.
					</Text>
				</Box>
				<Box sx={gridColumnRightSx} pb={["60px", "120px", "190px"]}>
					<Text
						variant="text.headlinelg"
						color="text.primaryalt"
						mb="12px"
						mt="30px"
					>
						Build your own library
					</Text>
					<Text variant="text.bodymd" color="text.primary">
						Collect your brightest design ideas and most used visual
						components in one convenient library to boost
						productivity, cut the expenses and take your Web Design
						business up a notch... or ten!
					</Text>
				</Box>
			</Box>
		</Box>
	</Box>
);

export default React.memo(VisualComponent);
