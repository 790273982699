import React from "react";
import { PageProps } from "gatsby";
import { ThemeProvider } from "emotion-theming";
import { useInView } from "react-intersection-observer";

import { Box } from "@graphite/uneon";
import themes from "../components/v2/libs/themes";
import Layout from "../components/v2/Layout";
import SEO from "../components/v2/SEO";
import YouWillLoveIt from "../components/v2/YouWillLoveIt";
import Try from "../components/v2/Try";
import Toolkit from "../components/v2/Toolkit";
import HybridGrid from "../components/v2/HybridGrid";
import LayerSystem from "../components/v2/LayerSystem";
import OneVision from "../components/v2/OneVision";
import Footer from "../components/v2/Footer";
import VisualComponent from "../components/v2/VisualComponent";
import StickyMenu from "../components/v2/StickyMenu";

const FeaturesPage: React.FC<PageProps<{}>> = () => {
	const [ref, inView] = useInView({
		threshold: 0.05,
		triggerOnce: false,
	});

	const [refGrid, selectGrid] = useInView({
		threshold: 0.1,
		triggerOnce: false,
	});

	const [refLayers, selectLayers] = useInView({
		threshold: 0.1,
		triggerOnce: false,
	});

	const [refDesign, selectDesign] = useInView({
		threshold: 0.1,
		triggerOnce: false,
	});

	const [refComponents, selectComponents] = useInView({
		threshold: 0.1,
		triggerOnce: false,
	});

	const [refFooter, selectFooter] = useInView({
		threshold: 0.1,
		triggerOnce: false,
	});

	const currentSelect = React.useMemo(() => {
		let select = "";
		if (selectGrid) select = "grid";
		if (selectLayers) select = "layers";
		if (selectDesign) select = "design";
		if (selectComponents) select = "components";
		if (selectFooter) select = "footer";

		return select;
	}, [
		selectComponents,
		selectDesign,
		selectFooter,
		selectGrid,
		selectLayers,
	]);

	return (
		<Layout>
			<SEO />
			<ThemeProvider theme={themes.dark}>
				<StickyMenu isShown={!inView} currentSelect={currentSelect} />
			</ThemeProvider>
			<Box ref={ref} bg="bg.primary">
				<Toolkit />
			</Box>
			<ThemeProvider theme={themes.light}>
				<Box id="grid" ref={refGrid} bg="bg.primary">
					<HybridGrid />
				</Box>
			</ThemeProvider>
			<Box id="layers" ref={refLayers} bg="bg.primaryminus">
				<LayerSystem />
			</Box>
			<ThemeProvider theme={themes.light}>
				<Box id="design" ref={refDesign} bg="bg.primary">
					<OneVision />
				</Box>
			</ThemeProvider>
			<Box id="components" ref={refComponents} bg="bg.primaryminus">
				<VisualComponent />
			</Box>
			<Box
				ref={refFooter}
				sx={{
					backgroundColor: "bg.primaryminus",
					position: "relative",
					"&:after": {
						content: '""',
						position: "absolute",
						top: 0,
						width: "100%",
						height: "420px",
						background:
							"linear-gradient(180deg, #191925 2.8%, rgba(25, 25, 37, 0) 100%)",
						zIndex: -1,
					},
				}}
			>
				<YouWillLoveIt />
				<Try />
				<Footer />
			</Box>
		</Layout>
	);
};

export default FeaturesPage;
