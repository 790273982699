import React from "react";
import styled from "@emotion/styled";
import { Flex, Box, Text } from "@graphite/uneon";
import TopBar from "../Hero/TopBar";

import rhombusImage from "./images/box.svg";
import ellipse1Img from "./images/ellipse1.svg";
import ellipse2Img from "./images/ellipse2.svg";
import ellipse3Img from "./images/ellipse3.svg";
import ellipse4Img from "./images/ellipse4.svg";

const Header = styled(Text)`
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent;
	background: linear-gradient(
		287.43deg,
		rgb(119, 77, 255) 33.27%,
		rgb(35, 158, 185) 61.72%,
		rgb(215, 62, 135) 82.93%
	);
	text-transform: uppercase;
	margin-top: 18px;
`;

const Conteiner = styled(Box)`
	position: relative;
	overflow: hidden;
`;

const TopBlock = () => (
	<Conteiner height={["450px", "555px"]}>
		<Box>
			<img
				src={ellipse1Img}
				alt=""
				style={{ position: "absolute", bottom: 0, left: 0 }}
			/>
			<img
				src={ellipse2Img}
				alt=""
				style={{ position: "absolute", top: 0, left: 0 }}
			/>
			<img
				src={ellipse3Img}
				alt=""
				style={{
					position: "absolute",
					top: 0,
					right: 0,
					objectFit: "cover",
					height: "100%",
				}}
			/>
			<img
				src={ellipse4Img}
				alt=""
				style={{ position: "absolute", top: 0, left: 0 }}
			/>
		</Box>
		<Box
			sx={{
				width: "100vw",
				height: "100%",
				position: "absolute",
				top: 0,
				left: 0,
				bg: "rgba(2, 2, 3, 0.7)",
			}}
		/>
		<Box variant="gridcontainer">
			<TopBar currentPage="features" />
			<Box
				sx={{
					zIndex: 10,
				}}
			>
				<Flex
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					mt="46px"
					textAlign="center"
				>
					<Header
						variant="sectionmd"
						color="text.accent"
						textTransform="uppercase"
						mt="18px"
						display={["none", "block"]}
					>
						Responsive. Pixel-Perfect. Code-Free.
					</Header>
					<Text
						variant="text.hero2"
						color="text.primaryalt"
						mt="30px"
					>
						Nextgen web design toolkit
					</Text>
					<Text variant="text.leadsm" color="text.primary" mt="24px">
						Focus on the creative part. We’ll cover the rest.
					</Text>
					<Box
						as="img"
						src={rhombusImage}
						alt=""
						sx={{
							bottom: ["-57px", 0],
							position: "absolute",
						}}
					/>
				</Flex>
			</Box>
		</Box>
	</Conteiner>
);

export default React.memo(TopBlock);
