import React from "react";
import { m, LazyMotion, domAnimation } from "framer-motion";
import styled from "@emotion/styled";
import { Flex, Button } from "@graphite/uneon";
import { useStaticQuery, graphql } from "gatsby";

type UrlData = {
	site: {
		siteMetadata?: {
			appUrl: string;
		};
	};
};

const Motion = styled(m.div)`
	position: fixed;
	z-index: 10000;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const Wrapper = styled(Flex)`
	min-width: 100vw;
	align-items: center;
`;

type Tprops = {
	readonly isShown: boolean;
	readonly currentSelect: string;
};

const StickyMenu = ({ isShown, currentSelect }: Tprops) => {
	const { site }: UrlData = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						appUrl
					}
				}
			}
		`
	);

	const appUrl = site.siteMetadata?.appUrl || "/";

	const variants = {
		open: { y: 0 },
		closed: { y: "-100%" },
	};

	return (
		<LazyMotion features={domAnimation}>
			<Motion
				initial="closed"
				animate={isShown && !!currentSelect ? "open" : "closed"}
				variants={variants}
				transition={{
					duration: 0.3,
				}}
			>
				<Wrapper variant="gridcontainer" bg="bg.primaryminus">
					<Button
						as="a"
						href="#grid"
						variant={
							currentSelect === "grid"
								? "accentflat.md"
								: "primaryaltflat.md"
						}
						data-link="try"
					>
						Grid
					</Button>
					<Button
						as="a"
						href="#layers"
						variant={
							currentSelect === "layers"
								? "accentflat.md"
								: "primaryaltflat.md"
						}
						data-link="try"
					>
						Layers
					</Button>
					<Button
						as="a"
						href="#design"
						variant={
							currentSelect === "design"
								? "accentflat.md"
								: "primaryaltflat.md"
						}
						data-link="try"
					>
						Design system
					</Button>
					<Button
						as="a"
						href="#components"
						variant={
							currentSelect === "components"
								? "accentflat.md"
								: "primaryaltflat.md"
						}
						data-link="try"
					>
						Components
					</Button>
					<Flex
						mt="15px"
						mb="15px"
						sx={{
							flexGrow: 1,
							justifyContent: "flex-end",
						}}
					>
						<Button
							as="a"
							href={`${appUrl}login`}
							variant="primaryaltflat.md"
							data-link="try"
						>
							Log in
						</Button>
						<Button
							as="a"
							href={`${appUrl}signup`}
							variant="warning.rounded.md"
							data-link="try"
						>
							Get statrted
						</Button>
					</Flex>
				</Wrapper>
			</Motion>
		</LazyMotion>
	);
};

export default React.memo(StickyMenu);
