import React from "react";
import { Box, Text, Button, Flex } from "@graphite/uneon";

import TopBlock from "./TopBlock";
import card1Img from "./images/card1.svg";
import card2Img from "./images/card2.svg";
import card3Img from "./images/card3.svg";
import card4Img from "./images/card4.svg";
import ellipse5Img from "./images/ellipse5.svg";
import ellipse6Img from "./images/ellipse6.svg";
import ellipse7Img from "./images/ellipse7.svg";

const gridColumnLeftSx = {
	display: "flex",
	flexDirection: "column",
	alignItems: "baseline",
	justifyContent: "space-beetween",
	gridColumn: ["span 6 !important", "span 6 !important", "1 / 6 !important"],
};

const gridColumnRightSx = {
	display: "flex",
	flexDirection: "column",
	alignItems: "baseline",
	justifyContent: "space-beetween",
	gridColumn: ["span 6 !important", "span 6 !important", "7 / 12 !important"],
};

const learnMoreBtnSx = {
	flexGrow: 1,
	alignItems: "flex-end",
};

const Toolkit = () => (
	<Box>
		<TopBlock />
		<Box sx={{ position: "relative" }}>
			<Box>
				<img
					src={ellipse5Img}
					alt=""
					style={{ position: "absolute", top: 0, left: 0 }}
				/>
				<img
					src={ellipse6Img}
					alt=""
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						objectFit: "cover",
						width: "100%",
					}}
				/>
				<img
					src={ellipse7Img}
					alt=""
					style={{ position: "absolute", bottom: 0, left: 0 }}
				/>
				<Box
					sx={{
						width: "100vw",
						height: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						bg: "rgba(0, 0, 0, 0.85)",
					}}
				/>
			</Box>
			<Box
				variant="gridcontainer"
				pt={["60px", "90px", "120px"]}
				sx={{ position: "relative", zIndex: 10 }}
			>
				<Box sx={gridColumnLeftSx} pb={["60px", "90px", "120px"]}>
					<img src={card1Img} alt="" />
					<Text
						variant="text.title3"
						color="text.primaryalt"
						mt="48px"
					>
						Hybrid grid
					</Text>
					<Text variant="bodylg" color="text.primary" mt="12px">
						Get the best out of absolute and grid-based positioning
						in one hybrid workspace. The comfort of graphic design
						and the precision of web-development synergized in one
						ultimate web design experience.
					</Text>
					<Flex sx={learnMoreBtnSx}>
						<Button
							as="a"
							href="#grid"
							variant="primaryghost.rounded.lg"
							mt="48px"
						>
							Learn more
						</Button>
					</Flex>
				</Box>
				<Box sx={gridColumnRightSx} pb={["60px", "90px", "120px"]}>
					<img src={card2Img} alt="" />
					<Text
						variant="text.title3"
						color="text.primaryalt"
						mt="48px"
					>
						Layer design
					</Text>
					<Text variant="bodylg" color="text.primary" mt="12px">
						We’ve brought the classic “layers” panel into the
						webdesign game, making the environment extra cozy and
						familiar, and your designer workflow – more fluid and
						comfortable.
					</Text>
					<Flex sx={learnMoreBtnSx}>
						<Button
							as="a"
							href="#layers"
							variant="primaryghost.rounded.lg"
							mt="48px"
						>
							Learn more
						</Button>
					</Flex>
				</Box>
				<Box sx={gridColumnLeftSx} pb={["60px", "90px", "120px"]}>
					<img src={card3Img} alt="" />
					<Text
						variant="text.title3"
						color="text.primaryalt"
						mt="48px"
					>
						Global design system
					</Text>
					<Text variant="bodylg" color="text.primary" mt="12px">
						From absolute positioning to absolute control. Introduce
						global or specific adjustments across multiple projects
						in mere seconds, create custom presets, variables and
						rules at any scale necessary.
					</Text>
					<Flex sx={learnMoreBtnSx}>
						<Button
							as="a"
							href="#design"
							variant="primaryghost.rounded.lg"
							mt="48px"
						>
							Learn more
						</Button>
					</Flex>
				</Box>
				<Box sx={gridColumnRightSx} pb={["104px", "90px", "120px"]}>
					<img src={card4Img} alt="" />
					<Flex sx={{ alignItems: "flex-end" }}>
						<Text
							sx={{ position: "relative" }}
							variant="text.title3"
							color="text.primaryalt"
							mt="48px"
						>
							Visual components
							<Text
								sx={{
									position: "absolute",
									padding: "3px 9px",
									bg: "bg.accent",
									borderRadius: "99px",
									top: 0,
									right: ["-18px", 0, "-18px"],
									transform: [
										"translate(100%, 2px)",
										"translate(50%, -100%)",
										"translate(100%, 8px)",
									],
								}}
								variant="text.sectionsm"
								color="text.oncolor"
							>
								Coming soon
							</Text>
						</Text>
					</Flex>
					<Text variant="bodylg" color="text.primary" mt="12px">
						Streamline your workflow! Pack your brightest ideas in
						custom components for future reuse and improvement,
						create your own libraries or import them from React.
					</Text>
					<Flex sx={learnMoreBtnSx}>
						<Button
							as="a"
							href="#components"
							variant="primaryghost.rounded.lg"
							mt="48px"
						>
							Learn more
						</Button>
					</Flex>
				</Box>
			</Box>
		</Box>
	</Box>
);

export default React.memo(Toolkit);
