import React from "react";
import { Box, Text } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import VideoBlock from "../VideoBlock";
import TryItButton from "../TryItButton";
import listIconImg from "./images/shape.svg";
import line1Img from "./images/line1.svg";
import line2Img from "./images/line2.svg";
import frameImg from "./images/frame.svg";

import flexibleMp4 from "./images/grid_flexible.mp4";
import flexibleWebm from "./images/grid_flexible.webm";

import responsiveMp4 from "./images/grid_responsive.mp4";
import responsiveWebm from "./images/grid_responsive.webm";

import customizationMp4 from "../TopFeature/images/grid.mp4";
import customizationWebm from "../TopFeature/images/grid.webm";

const listStyle = {
	listStyleImage: `url(${listIconImg})`,
	paddingLeft: "17px",
	marginLeft: "-5px",
	marginRight: "10%",
};

const listItemStyle = {
	paddingLeft: "5px",
};

const gridColumnLeftSx = {
	gridColumn: ["span 6 !important", "span 6 !important", "1 / 6 !important"],
};

const gridColumnRightSx = {
	gridColumn: ["span 6 !important", "span 6 !important", "7 / 12 !important"],
};

const HybridGrid = () => {
	const { video7, video8, video9 } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					appUrl
				}
			}
			video7: file(relativePath: { eq: "video7.jpg" }) {
				...videoImage
			}
			video8: file(relativePath: { eq: "video8.jpg" }) {
				...videoImage
			}
			video9: file(relativePath: { eq: "video9.jpg" }) {
				...videoImage
			}
		}
	`);

	return (
		<Box pb={["40px"]}>
			<Box
				variant="gridcontainer"
				pt={["102px", "102px", "180px"]}
				pb={["90px", "90px", "120px"]}
			>
				<Box
					sx={{
						gridColumn: ["span 6 !important"],
					}}
				>
					<Text
						variant="text.hero1"
						color="text.primaryalt"
						mb="72px"
					>
						Hybrid grid
					</Text>
					<Box
						sx={{
							display: ["none", "flex"],
						}}
					>
						<TryItButton />
					</Box>
				</Box>
				<Box
					sx={{
						gridColumn: ["span 6 !important"],
					}}
				>
					<Text variant="text.leadsm" color="text.primary" pb="54px">
						Get the best out of both worlds: switch between absolute
						and grid-based positioning on the fly, or better yet –
						combine the two methods in one workspace with the
						innovative Hybrid Grid technology.
					</Text>
					<Box
						sx={{
							display: ["flex", "none"],
						}}
					>
						<TryItButton />
					</Box>
				</Box>
			</Box>
			<VideoBlock
				image={video7.childImageSharp.fluid.base64}
				mp4={responsiveMp4}
				webm={responsiveWebm}
			>
				<Text variant="text.title1" color="text.primaryalt">
					Responsive by&nbsp;design
				</Text>
				<Text variant="text.bodylg" color="text.primary" mt="24px">
					Anything you see or create in your Graphite workspace is
					responsive by design. Just focus on pouring out your
					creative genius on the canvas, and our tool will take care
					of all the technicalities:
				</Text>
				<ul style={listStyle}>
					<li style={listItemStyle}>
						<Text
							variant="text.bodymd"
							color="text.primary"
							mt="30px"
						>
							Add new elements and containers to your web-page –
							where you like them, how you like them, when you
							like them.
						</Text>
					</li>
					<li style={listItemStyle}>
						<Text
							variant="text.bodymd"
							color="text.primary"
							mt="30px"
						>
							Graphite will automatically ensure that your
							web-page is consistently breathtaking and fully
							functional across all screens – from mobile devices
							to desktop computers and beyond.
						</Text>
					</li>
				</ul>
			</VideoBlock>
			<VideoBlock
				image={video8.childImageSharp.fluid.base64}
				reverse
				mp4={customizationMp4}
				webm={customizationWebm}
			>
				<Text variant="text.title1" color="text.primaryalt">
					Fully customizable
				</Text>
				<Text variant="text.bodylg" color="text.primary" mt="24px">
					Your grid – your rules! So customize the hell out of it:
					from the desired number of columns and gutter values to
					container width and margin and padding properties.
				</Text>
				<Text variant="text.bodylg" color="text.primary" mt="24px">
					{`Wait until you hear the best part – each and every
						element on the grid will automatically adapt to any
						change you've made to ensure consistency.`}
				</Text>
				<Box mt="54px">
					<TryItButton />
				</Box>
			</VideoBlock>
			<Box variant="gridcontainer">
				<Box sx={gridColumnLeftSx} pb="120px">
					<img src={line2Img} alt="" />
					<Text
						variant="text.title4"
						color="text.primaryalt"
						mb="12px"
						mt="30px"
					>
						Make your own grid
					</Text>
					<Text variant="text.bodymd" color="text.primary">
						Not entirely happy with what Bootstrap has to offer or
						need a little extra for a very special occasion? Why not
						create your own grid in a matter of minutes: from the
						desired number of columns and gutter values to container
						width and margin and padding properties
					</Text>
				</Box>
				<Box sx={gridColumnRightSx} pb="120px">
					<img src={line1Img} alt="" />
					<Text
						variant="text.title4"
						color="text.primaryalt"
						mb="12px"
						mt="30px"
					>
						Consistent all the way through
					</Text>
					<Text variant="text.bodymd" color="text.primary">
						{`Each and every element will automatically adapt to
							any change you've made to ensure consistency. So you
							needn't worry about how one minor tweak will tumble
							the entire design – we got this part covered for
							you.`}
					</Text>
				</Box>
			</Box>
			<VideoBlock
				image={video9.childImageSharp.fluid.base64}
				mp4={flexibleMp4}
				webm={flexibleWebm}
			>
				<Text variant="text.title1" color="text.primaryalt">
					Flexible
				</Text>
				<Text variant="text.bodylg" color="text.primary" mt="24px">
					Every Web Site layout – no matter how complicated or
					exquisite – can be created from scratch right on the canvas
					before you. Honestly, let your design dreams go wild and
					focus on the creative part, while we crunch all the code for
					you behind the scenes.
				</Text>
				<Box variant="variants.widget" mt="36px">
					<img src={frameImg} alt="" />
					<Text
						variant="text.headlinemd"
						color="text.primary"
						mt="24px"
					>
						Container widget
					</Text>
					<Text variant="text.bodymd" color="text.primary" mt="12px">
						Utilizing all the firepower of modern CSS and Flexbox,
						Graphite Space will bring your most daring design ideas
						to life as a production-ready web page – in one
						flawless, code-less, pixel-perfect move.
					</Text>
				</Box>
				<Box mt="54px">
					<TryItButton />
				</Box>
			</VideoBlock>
		</Box>
	);
};

export default React.memo(HybridGrid);
