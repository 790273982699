import React from "react";
import { Button } from "@graphite/uneon";
import { useStaticQuery, graphql } from "gatsby";

type UrlData = {
	site: {
		siteMetadata?: {
			appUrl: string;
		};
	};
};

const TryItButton = () => {
	const { site }: UrlData = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						appUrl
					}
				}
			}
		`
	);

	const appUrl = site.siteMetadata?.appUrl || "/";

	return (
		<Button
			as="a"
			display="inline-flex"
			href={`${appUrl}signup`}
			variant="warning.rounded.lg"
		>
			Try it today
		</Button>
	);
};

export default React.memo(TryItButton);
