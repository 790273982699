import React from "react";
import styled from "@emotion/styled";
import { Box } from "@graphite/uneon";
import { useInView } from "react-intersection-observer";
import Img from "gatsby-image";

const VideoContainer = styled(Box)`
	video {
		width: 100%;
	}
`;

const Video = styled.video`
	border-radius: 8px;
	width: 100%;
`;

const BackgroundImg = styled(Img)`
	border-radius: 8px;
	width: 100%;
`;

type Tprops = {
	readonly children: JSX.Element[] | JSX.Element;
	readonly reverse?: boolean;
	readonly image: any;
	readonly mp4?: any;
	readonly webm?: any;
};

// Тут должно быть видео, но Витя хер забил
const VideoBlock = ({ children, image, reverse, mp4, webm }: Tprops) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
	});
	return (
		<Box ref={ref} variant="gridcontainer">
			<Box
				sx={{
					gridColumn: [
						"span 6 !important",
						"span 12 !important",
						`${reverse ? "9 / " : ""}span 4 !important`,
					],
				}}
				pb={["60px", "90px", "120px"]}
			>
				{children}
			</Box>
			<VideoContainer
				sx={{
					order: [1, 1, reverse && -1],
					gridColumn: [
						"span 6 !important",
						"span 12 !important",
						`${reverse ? "" : "6 / "}span 7 !important`,
					],
				}}
				pb={["80px", "90px", "180px"]}
			>
				{(!mp4 || !webm) && <BackgroundImg fluid={image} />}
				{(mp4 || webm) && (
					<Video autoPlay loop muted playsInline poster={image}>
						{inView && (
							<>
								<source src={mp4} type="video/webm" />
								<source src={webm} type="video/mp4" />
							</>
						)}
					</Video>
				)}
			</VideoContainer>
		</Box>
	);
};

export default React.memo(VideoBlock);
